import React from "react";
import classNames from "classnames";
import Svg from "react-inlinesvg";
import Curve from "../../images/shapes/curves/curve-3.svg";

const GrayCurve3 = ({ className }) => {
  return (
    <div className={classNames("position-relative", className)}>
      <div className="shape shape-bottom shape-fluid-x text-gray-200">
        <Svg src={Curve} />
      </div>
    </div>
  );
};

export default GrayCurve3;
