import React from "react";
import Layout from "../components/Layout";
import { BlueCurve1, GrayCurve1, GrayCurve3 } from "../components/Curves";
import {
  WelcomeSection,
  DetailsSection,
  AboutSection,
  PricingSection,
  FaqSection,
  CtaSection,
  IntegrationsSection,
} from "../sections";

const IndexPage = () => {
  return (
    <Layout title="ChatIQ | Home">
      <WelcomeSection />
      <DetailsSection />
      <AboutSection />
      <IntegrationsSection />
      <GrayCurve3 className="mt-n8" />
      <PricingSection />
      <BlueCurve1 className="mt-n15" />
      <FaqSection />
      <CtaSection />
      <GrayCurve1 />
    </Layout>
  );
};

export default IndexPage;
